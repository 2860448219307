import XHR from '@/request/http'
import {composSun,computeMax,computeExpert,computeMachine} from '@/global/utils.js'
const $h = new XHR()
// url
const url = {
    lists:'/admin/v1/lesson/page',
    create:'/admin/v1/lesson/create',//新建
    update:'/admin/v1/lesson/update',//编辑评课视频
    del:'/admin/v1/lesson/del', //删除评课视频
    get:'/admin/v1/lesson/get', //获取
    portrait:'/admin/v1/lesson/portrait', //获取本次课程画像
    reviewRecord:'/admin/v1/lesson/reviewRecord', //获取本地画像自己的打分
    review:'/admin/v1/lesson/review/create', //专家提交评课报告
    template:'/admin/v1/lesson/template', //获取本次评课模板信息
    recently:'/admin/v1/lesson/recently', //获取老师最近10次得分情况
    lesson_submit:'/admin/v1/lesson/review/submit',//提交评价
    lesson_start:'/admin/v1/lesson/review/start',//开始评课
    lesson_review:'/admin/v1/lesson/repeatReview',//重新评课
    lesson_chart:'/admin/v1/lesson/statistic',
    lesson_source:'/admin/v1/lesson/recommendResource',//教研资源推荐
    lesson_video:'/admin/v1/lesson/video/url', //获取腾讯云视频
    lesson_license:'/admin/v1/lesson/video/license',//
    lesson_video_count:'/admin/v1/lesson/video/count',//观看人数
    lesson_out_upload:'/admin/v1/file/url/upload',
}

//评课
export async function lists(data)
{
    return await $h.request(url.lists,data,'GET')
}

//create
export async function create(data)
{
    return $h.request(url.create,data,'POST')
}

//更新
export async function update(data)
{
    return $h.request(url.update,data,'POST')
}

//删除
export async function del(data)
{
    return $h.request(url.del,data,'POST')
}

export async function get(id)
{
    return $h.request(url.get,{id:id},'GET')
}
export async function template(id)
{
    return $h.request(url.template,{lessonId:id},'GET')
}
export async function portrait(id)
{
    let res = await $h.request(url.portrait,{lessonId:id},'GET')
    let data = res.data
    let expert = data.rules //人评
    let machine = data.rules //机评
    let finalScore = data.finalScore // 总得分
    let complexRules = data.complexRules // 综合得分(人机)
    let expertRules = data.rules // 专家打分
    let machineRules = data.rules //机器打分
    let logs = data.logs //视频过程记录
    let arr = [] // 列表

    // if(expert) {
    //     arr.push({
    //         name:"人评",
    //         itemStyle: {
    //             color: '#E53B00'
    //         },
    //         children:computeExpert(expert)
    //     })
    //     expert.forEach(item=>{
    //         item.children.forEach(itx=>{
    //             itx['max'] =  computeMax(itx.maxScore,itx.score)
    //         })
    //     }) 
    // }
    
    // if(machine){
    //     arr.push({
    //         name:"机评",
    //         itemStyle: {
    //             color: '#0052DA'
    //         },
    //         children:computeMachine(machine)
    //     })
    // }
    if(data.rules) {
        arr.push({
            name:"综合得分",
            itemStyle: {
                color: '#0052DA'
            },
            children:composSun(data.rules)
        })
    }
    // console.log(composSun(data.rules))

    //计算星星
    complexRules && complexRules.forEach(item=>{
        item.children.forEach(itx=>{
            itx['max'] =  computeMax(itx.maxScore,itx.score)
        })
    })  
    

    //指标人机柱状图对比
    let rule_obj = {
        names:['人评','机评'],
        params:[],
        data:[[],[]],
        // params:['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        // data:[[2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],[6.0, 5.9, 7.0, 21.2, 21.6, 71.7, 131.6, 161.2, 31.6, 21.0, 1.4, 5.3]]
    }

    //指标集合
    let rule = [];

    data.rules.forEach(item=>{
        item.children.forEach(itx=>{
            rule.push(itx)
            rule_obj.params.push(itx.name)
            let _machineScore = itx.machineScore ? itx.machineScore : 0
            let _expertScore  = itx.expertScore ? itx.expertScore : 0
            rule_obj.data[0].push(_expertScore)
            rule_obj.data[1].push(_machineScore)
        })
    }) 

    return {
        res,
        expert,
        machine,
        finalScore,
        arr,
        complexRules,
        logs,
        expertRules,
        machineRules,
        rule_obj,
        rule
    }
}

export async function reviewRecord(lesson_id){
    let res = await $h.request(url.reviewRecord,{lessonId:lesson_id},'GET')
    let data = res.data
    let rules = (data && data.rules) || []
    let logs = (data && data.logs) || [] //视频过程记录
    //计算星星
    rules && rules.forEach(item=>{
        item.children.forEach(itx=>{
            itx['max'] =  computeMax(itx.maxScore,itx.score)
        })
    }) 
    return {
        res,
        data,
        logs,
        rules
    }
}

//
export async function recently(id)
{
    return $h.request(url.recently,{teacherId:id},'GET')
}

export async function lesson_submit(data)
{
    return $h.request(url.lesson_submit,data,'POST')
}

export async function lesson_start(id)
{
    return $h.request(url.lesson_start,{lessonId:id},'POST')
}

export async function lesson_review(id)
{
    return $h.request(url.lesson_review,{lessonId:id},'POST')
}
//教研资源推荐
export async function lesson_source(id)
{
    return $h.request(url.lesson_source,{lessonId:id},'GET')
}

export async function lesson_chart(id)
{
    let res = await $h.request(url.lesson_chart,{lessonId:id},'GET')
    
    let radar_data = [];
    let indicator_data = []
    let group_name_lists = [];
    let arr = [];
    if(res.code==0) {
        let data = res.data.list

        data.forEach(item=>{
            radar_data.push(item.finalScore)
            indicator_data.push({
                name:item.groupName,
                max:item.maxScore
            })
            group_name_lists.push(item.groupName)
            let obj = {
                'product':item.groupName,
                '机评':item.machineScore,
                '专家评':item.expertScore,
                '最终评分':item.finalScore
            }
            arr.push(obj)
        })    
    }
    
    return {
        res,
        arr,
        radar_data,
        indicator_data,
        group_name_lists
    }
}
 
export async function lesson_video(lesson_id) {
    return $h.request(url.lesson_video,{lessonId:lesson_id},'GET')
}

export async function lesson_license() {
    return $h.request(url.lesson_license,'GET')
}


export async function lesson_video_count(lesson_id) {
    return $h.request(url.lesson_video_count,{lessonId:lesson_id},'GET')
}

export function lesson_out_upload(videoUrl) {
    return $h.postStream(url.lesson_out_upload,{videoUrl:videoUrl})
}