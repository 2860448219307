export const mergeObjects = (obj1, obj2)=> {  
    // 创建一个新对象，用于存储合并后的结果  
    const mergedObj = {};  
    
    // 遍历 obj1 的所有属性  
    for (const key in obj1) {  
        if (Object.prototype.hasOwnProperty.call(obj1, key)) {  
            // 如果 obj2 中也存在相同的属性，则递归合并该属性  
            if (Object.prototype.hasOwnProperty.call(obj2, key)) {  
                mergedObj[key] = mergeObjects(obj1[key], obj2[key]);  
            } else {  
                // 如果 obj2 中不存在该属性，则直接将 obj1 中的属性复制到 mergedObj 中  
                mergedObj[key] = obj1[key];  
            }  
        }  
    }
    
    // 遍历 obj2 中剩余的属性  
    for (const key in obj2) {  
        if (Object.prototype.hasOwnProperty.call(obj2, key) && !Object.prototype.hasOwnProperty.call(mergedObj, key)) {  
            mergedObj[key] = obj2[key];  
        }  
    }  
    
    return mergedObj;  
}

//计算max星
export const computeMax = (maxScore,score) => {
    let max = 5
    let stars = Math.ceil( score / ( (maxScore / max).toFixed(2) * 1) )
    return stars > max?max:stars
}

//计算得分
export const computeScore = (maxScore,max) => {
    let _max = 5
    return (maxScore/_max*max).toFixed(2)*1
}

//组合专家
export const computeExpert = (arr) =>{
    let all = [];
    arr.forEach(item=>{
        //指标组
        let group = item.children.filter(obj=>[3,4,5].includes(obj.reviewType))
        if(group.length) {
            let obj = {
                name:item.groupName,
                children:[]
            };
            let children = {}
            group.forEach(itx=>{
                let max = computeMax(itx.maxScore,itx.expertScore)
                if(!children[max]) {
                    children[max] = []
                }
                children[max].push(itx)
            })
            for(var attr in children) {
                obj.children.push({
                    name:attr+'☆',
                    children:children[attr]
                })
            }

            all.push(obj)
        }
    })

    return all
}

//组合机器
export const computeMachine =  (arr) =>{
    let all = [];
    arr.forEach(item=>{
        //指标组
        let group = item.children.filter(obj=>[1,2,4,5].includes(obj.reviewType))
        if(group.length) {
            let obj = {
                name:item.groupName,
                children:[]
            };
            let children = {}
            group.forEach(itx=>{
                let max = computeMax(itx.maxScore,itx.machineScore)
                if(!children[max]) {
                    children[max] = []
                }
                children[max].push(itx)
            })
            for(var attr in children) {
                obj.children.push({
                    name:attr+'☆',
                    children:children[attr]
                })
            }

            all.push(obj)
        }
    })

    return all
}


//计算指标
export const composSun = (arr)=>{
    let all = [];
    arr.forEach(item => {
        //指标项
        let obj = {
            name:item.groupName,
            children:[]
        };
        let children = {}
        item.children.forEach(itx=>{
            let max = computeMax(itx.maxScore,itx.score)
            if(!children[max]) {
                children[max] = []
            }
            children[max].push(itx)
        })

        for(var attr in children) {
            obj.children.push({
                name:attr+'☆',
                children:children[attr]
            })
        }

        all.push(obj)
    });
    return all
}