<template>
    <div style="position: relative">
        <div style="position: absolute;left: 10px;top: 10px;z-index: 1000;">
            <div class="ls_count">{{videoCount}}</div>
        </div>
        <div id="J_prismPlayer"></div>
    </div>
</template>

<script>
export default {
    props:{
        url:String
    },
    data(){
        return {
            videoCount:0
        }
    },
    watch:{
        url() {
            this.init()
        }
    },
    created(){

    },
    mounted(){
        this.init()
    },
    methods:{
        async init(){
            let self = this
            this.getStartLiveVideo(self.$props.url)    
            // this.player = TCPlayer('player-contai    ner-id', {
            //     sources: [{
            //         src: self.$props.url, // 播放地址
            //     }],
            //     licenseUrl:self.license
            //     // licenseUrl: 'license/url', // license 地址，参考准备工作部分，在视立方控制台申请 license 后可获得 licenseUrl
            // });
            
            // this.player.on('error',()=>{
            //     console.log("播放视频错误")
            // })
            // this.player.on('ended',()=>{
            //     this.$alert("直播已结束", '提示', {confirmButtonText: '关闭'});
            //     this.player.dispose()
            // })
        },
        getStartLiveVideo(url){
            self.aliplayer = new window.Aliplayer({
                id: 'J_prismPlayer',
                source: url, // 播放地址，可以是第三方直播地址，或阿里云直播服务中的拉流地址。
                isLive: true, // 是否为直播播放。
                autoplay:false, //
            },function(player){
                
            });
        },
        //统计直播观看人数
        getVideoCount(){
            
        },
    },
    
    beforeDestroy(){
        // console.log("销毁播放器")
        this.player.dispose()
        clearInterval(this.timer)
    }
}
</script>

<style scoped lang="less">
.ls_count {
    background-color: rgba(63, 133, 255,0.6);
    width: 80px;
    height: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    color: #fff;
    font-size: 15px;
}
</style>