<template>
<div class="mobile-menu">
    <div class="container">
        <div class="pack-name">智慧教研平台</div>
        <div class="han-box" @click="handleChangeShow">
            <img v-if="show" src="../assets/imgs/mob/menu-close.png" alt srcset />
            <img v-else src="../assets/imgs/mob/menu-open.png" alt srcset />
        </div>
    </div>

    <div v-show="show" class="m-menu-list">
        <ul>
            <!-- <li v-for="(item) in data" :key="item.keys">
                <div @click="()=>handleClickMenu(item)">{{item.label}}</div>
            </li> -->
            <li>
                <div><router-link class="_link" :to="{name:'home',query:$route.query}">首页</router-link></div>
                <!-- <div @click="$router.push({name:'home',query:$route.query})">首页</div> -->
            </li>
            <li>
                <div><router-link class="_link" :to="{name:'screen_teacher',query:$route.query}">教师当前画像</router-link></div>
                <!-- <div @click="$router.push({name:'screen_teacher',query:$route.query})">教师当前画像</div> -->
            </li>
            <li>
                <div><router-link class="_link" :to="{name:'screen_class',query:$route.query}">课堂当前画像</router-link></div>
                <!-- <div @click="$router.push({name:'screen_class',query:$route.query})">课堂当前画像</div> -->
            </li>
            <li>
                <div><router-link class="_link" :to="{name:'screen_report',query:$route.query}">生产报告</router-link></div>
                <!-- <div @click="$router.push({name:'screen_report',query:$route.query})">生产报告</div> -->
            </li>
            <li v-if="$route.query.teacher_id == $store.state.user.id && $route.name=='screen_class'">
                <div @click="handleClickMenu('reflect')">教学反思</div>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
        };
    },
    methods: {
        handleChangeShow() {
            this.show = !this.show;
        },
        handleClickMenu(name){
            // if(menu.name) {
            //     this.$router.push({name:menu.name,query:this.$route.query})    
            // }
            this.show = false
            this.$emit('on-click',name);
        }
    }
};
</script>

<style lang="less" scoped>
._link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.mobile-menu {
  box-sizing: border-box;
  width: 100%;
  height:50px;
  background: #ffffff;

  padding-left: 2.67rem;

  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .pack-name {
    font-size: 3.73rem;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #409eff;
    line-height: 2.4rem;
  }
  .han-box {
    padding: 0 3.67rem;
    height: 100%;
    line-height: 13.73rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    img {
      width: 4.27rem;
      display: inline-block;
    }
  }
}

.m-menu-list {
  position: fixed;
  top: 11.73rem;
  left: 0;
  width: 100%;
  height: calc(100vh - 11.73rem);
  background: #f7f9fb;
  opacity: 0.95;
  z-index: 999;
  box-sizing: border-box;
  padding: 0 4rem;

  ul li div {
    height: 11.2rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-bottom: 0.13rem solid #cecece;

    font-size: 3.2rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
}
</style>
